import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ClpEnvironment } from '@client-portal/environment';
import { HttpClient } from '@angular/common/http';
import { finalize, first } from 'rxjs';
import { LocalStorageService } from '@client-portal/services/localstorage';
import { LogOutHttpPaths } from './log-out-http.paths';

@Injectable({
  providedIn: 'root',
})
export class LogOutService {
  constructor(
    private _auth: AuthService,
    private _env: ClpEnvironment,
    private _httpClient: HttpClient,
    private _localStorage: LocalStorageService,
  ) {}

  public logOut(): void {
    this._httpClient
      .put(`${this._env.apiEndpoint}${LogOutHttpPaths.logOut}`, null)
      .pipe(
        finalize(() => {
          this._localStorage.removeItem('division');
          this._auth.logout({
            logoutParams: {
              returnTo: this._env.auth0.logoutUrl,
            },
          });
        }),
        first(),
      )
      .subscribe();
  }
}
